<template>
<div>
    <form-wizard color="#FF2447" :title="null" :subtitle="null" finish-button-text="Apply Now" back-button-text="Previous" class="steps-transparent mb-3" @on-complete="ApplyServices">
        <!-- Select Services -->
        <tab-content title="Select Services" icon="feather icon-file-text" :before-change="checkIfSelected">
            <b-row>
                <b-col>

                    <b-form-radio v-model="Selected" plain name="some-radios3" value="USSD">
                        <b-card no-body class="card-developer-meetup">
                            <div class="rounded-top text-center">
                                <b-img src="https://miro.medium.com/max/1838/1*bzkSjugKawFjon1vCHqKFg.png" alt="Meeting Pic" height="170" />
                            </div>
                            <b-card-body>
                                <!-- metting header -->
                                <div class="meetup-header d-flex align-items-center">

                                    <div class="my-auto">
                                        <b-card-title class="mb-25">
                                            Click to Apply USSD Services Code
                                        </b-card-title>
                                        <b-card-text class="mb-0">
                                            e.g *300#
                                        </b-card-text>
                                    </div>
                                </div>
                                <!--/ metting header -->

                                <!-- media -->
                                <b-media v-for="ussd in USSDData" :key="ussd.avatar" no-body>

                                    <b-media-body>
                                        <h6 class="mb-0">
                                            {{ ussd.title }}
                                        </h6>
                                        <small>{{ ussd.subtitle }}</small>
                                    </b-media-body>
                                </b-media>

                                <!-- avatar group -->

                            </b-card-body>

                        </b-card>
                    </b-form-radio>
                </b-col>

                <b-col>

                    <b-form-radio v-model="Selected" plain name="some-radios3" value="SENDERID">
                        <b-card no-body class="card-developer-meetup">
                            <div class="bg-light-primary rounded-top text-center">
                                <b-img :src="require('@/assets/images/illustration/email.svg')" alt="Meeting Pic" height="170" />
                            </div>
                            <b-card-body>
                                <!-- metting header -->
                                <div class="meetup-header d-flex align-items-center">

                                    <div class="my-auto">
                                        <b-card-title class="mb-25">
                                            Click to Apply Sender ID
                                        </b-card-title>
                                        <b-card-text class="mb-0">
                                            eg Fleeks
                                        </b-card-text>
                                    </div>
                                </div>
                                <!--/ metting header -->

                                <!-- media -->
                                <b-media v-for="sms in smsData" :key="sms.avatar" no-body>

                                    <b-media-body>
                                        <h6 class="mb-0">
                                            {{ sms.title }}
                                        </h6>
                                        <small>{{ sms.subtitle }}</small>
                                    </b-media-body>
                                </b-media>

                            </b-card-body>

                        </b-card>

                    </b-form-radio>
                </b-col>
                <b-col>

                    <b-form-radio v-model="Selected" plain name="some-radios3" value="TwoShortCode">
                        <b-card no-body class="card-developer-meetup">
                            <div class="rounded-top text-center">
                                <b-img src="https://img.freepik.com/free-vector/email-mobile-notifications-with-smartphone-envelope-icons_25147-12.jpg?size=626&ext=jpg" alt="Meeting Pic" height="170" />
                            </div>
                            <b-card-body>
                                <!-- metting header -->
                                <div class="meetup-header d-flex align-items-center">

                                    <div class="my-auto">
                                        <b-card-title class="mb-25">
                                            Click to Apply Two-Way SMS ShortCode
                                        </b-card-title>
                                        <b-card-text class="mb-0">
                                            e.g 23454
                                        </b-card-text>
                                    </div>
                                </div>
                                <!-- media -->
                                <b-media v-for="twoWay in twoWayData" :key="twoWay.avatar" no-body>
                                    <b-media-body>
                                        <h6 class="mb-0">
                                            {{ twoWay.title }}
                                        </h6>
                                        <small>{{ twoWay.subtitle }}</small>
                                    </b-media-body>
                                </b-media>

                            </b-card-body>

                            <!-- avatar group -->

                        </b-card>
                    </b-form-radio>
                </b-col>

            </b-row>

            <b-row>
                <b-col>
                    <b-form-radio v-model="Selected" plain name="some-radios3" value="SURVEY">
                        <b-card no-body class="card-developer-meetup">
                            <div class="rounded-top text-center">
                                <b-img src="https://www.nonprofithr.com/wp-content/uploads/2020/04/Survey-time.jpg" alt="Meeting Pic" height="170" />
                            </div>
                            <b-card-body>
                                <!-- metting header -->
                                <div class="meetup-header d-flex align-items-center">

                                    <div class="my-auto">
                                        <b-card-title class="mb-25">
                                            Click to Apply Survey
                                        </b-card-title>
                                        <b-card-text class="mb-0">
                                            e.g Product Performance
                                        </b-card-text>
                                    </div>
                                </div>
                                <!--/ metting header -->

                                <!-- media -->
                                <b-media v-for="survey in SURVEYData" :key="survey.avatar" no-body>

                                    <b-media-body>
                                        <h6 class="mb-0">
                                            {{ survey.title }}
                                        </h6>
                                        <small>{{ survey.subtitle }}</small>
                                    </b-media-body>
                                </b-media>

                                <!-- avatar group -->

                            </b-card-body>

                        </b-card>
                    </b-form-radio>
                </b-col>
                <b-col>

                    <b-form-radio v-model="Selected" plain name="some-radios3" value="ShortCode">
                        <b-card no-body class="card-developer-meetup">
                            <div class=" rounded-top text-center">
                                <b-img src="https://mococheck.com/app/uploads/2015/02/img_superior@2x.png" alt="Meeting Pic" height="170" />
                            </div>
                            <b-card-body>
                                <!-- metting header -->
                                <div class="meetup-header d-flex align-items-center">

                                    <div class="my-auto">
                                        <b-card-title class="mb-25">
                                            Click to Apply Premium SMS Shortcode
                                        </b-card-title>
                                        <b-card-text class="mb-0">
                                            e.g 23454
                                        </b-card-text>
                                    </div>
                                </div>
                                <!--/ metting header -->

                                <!-- media -->
                                <b-media v-for="premium in premiumData" :key="premium.avatar" no-body>

                                    <b-media-body>
                                        <h6 class="mb-0">
                                            {{ premium.title }}
                                        </h6>
                                        <small>{{ premium.subtitle }}</small>
                                    </b-media-body>
                                </b-media>

                            </b-card-body>
                            <!-- avatar group -->

                        </b-card>
                    </b-form-radio>
                </b-col>
            </b-row>
        </tab-content>

        <!-- Application -->
        <tab-content title="Application" icon="feather icon-file" :before-change="checkIfRecordEnter">
            <b-row v-show="Selected=='SENDERID'">
                <b-col cols="12" class="mb-2">
                    <h5 class="mb-0">
                        Sender ID Application
                    </h5>
                    <small class="text-muted">Enter information for Sender ID application</small>
                </b-col>
                <b-col md="6">
                    <b-form-group label-for="i-first-shortcode" label="Sender Id">
                        <b-form-input id="i-first-shortcode" placeholder="Fleek" v-model="shortcode" />
                    </b-form-group>
                </b-col>
                <b-col md="6">
                    <b-form-group label-for="i-first-shortcode" label="Select Network">
                        <v-select v-model="networkSelected" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="option" multiple class="flex-grow-1 email-cc-selector" :close-on-select="false"  label="title">
                            <template #option="{ title, icon }">
                                <feather-icon :icon="icon" size="16" class="align-middle mr-50" />
                                <span> {{ title }}</span>
                            </template>
                        </v-select>
                    </b-form-group>
                </b-col>
                <b-col cols="12">
                    <b-form-group label="Description" label-for="description-area">
                        <b-form-textarea id="description-area" v-model="description" rows="4" placeholder="Enter your description here" />
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row v-show="Selected=='TwoShortCode'">
                <b-col cols="12" class="mb-2">
                    <h5 class="mb-0">
                        Two Way Shortcode Application
                    </h5>
                    <small class="text-muted">Enter information for Shortcode Application</small>
                </b-col>
                <b-col md="6">
                    <b-form-group label-for="i-first-shortcode" label="Two Way Shortcode">
                        <b-form-input id="i-first-shortcode" type="number" placeholder="23454" v-model="shortcode" />
                    </b-form-group>
                </b-col>
                <b-col md="6">
                    <b-form-group label-for="i-first-network" label="Select Network">
                       <v-select v-model="networkSelected" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="option" multiple class="flex-grow-1 email-cc-selector" :close-on-select="false"  label="title">
                            <template #option="{ title, icon }">
                                <feather-icon :icon="icon" size="16" class="align-middle mr-50" />
                                <span> {{ title }}</span>
                            </template>
                        </v-select>
                    </b-form-group>
                </b-col>
                <b-col md="6">
                    <b-form-group label-for="i-first-shortcode" label="Callback URL">
                        <b-form-input id="i-first-shortcode" type="url" required placeholder="https://example.com/callback" v-model="callbackURL" />
                    </b-form-group>
                </b-col>
                <b-col md="6">
                    <b-form-group label-for="i-first-shortcode" label="Delivery URL">
                        <b-form-input id="i-first-shortcode" type="url" required placeholder="https://example.com/dlr" v-model="dlrURL" />
                    </b-form-group>
                </b-col>
                <b-col md="6">
                    <b-form-group label-for="i-first-shortcode" label="Billing Rate">
                        <b-form-input id="i-first-shortcode" type="number" required placeholder="5" v-model="billingRate" />
                    </b-form-group>
                </b-col>
                <b-col cols="12">
                    <b-form-group label="Description" label-for="description-area">
                        <b-form-textarea id="description-area" v-model="description" rows="4" placeholder="Enter your description here" />
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row v-show="Selected=='ShortCode'">
                <b-col cols="12" class="mb-2">
                    <h5 class="mb-0">
                        Shortcode Application
                    </h5>
                    <small class="text-muted">Enter information for Shortcode Application</small>
                </b-col>
                <b-col md="6">
                    <b-form-group label-for="i-first-shortcode" label="Shortcode">
                        <b-form-input id="i-first-shortcode" type="number" placeholder="23451" v-model="shortcode" />
                    </b-form-group>
                </b-col>
                <b-col md="6">
                    <b-form-group label-for="i-first-network" label="Select Network">
                        <v-select v-model="networkSelected" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="option" multiple class="flex-grow-1 email-cc-selector" :close-on-select="false"  label="title">
                            <template #option="{ title, icon }">
                                <feather-icon :icon="icon" size="16" class="align-middle mr-50" />
                                <span> {{ title }}</span>
                            </template>
                        </v-select>
                    </b-form-group>
                </b-col>
                <b-col md="6">
                    <b-form-group label-for="i-first-shortcode" label="Callback URL">
                        <b-form-input id="i-first-shortcode" type="url" placeholder="https://example.com/callback" v-model="callbackURL" />
                    </b-form-group>
                </b-col>
                <b-col md="6">
                    <b-form-group label-for="i-first-shortcode" label="Delivery URL">
                        <b-form-input id="i-first-shortcode" type="url" placeholder="https://example.com/dlr" v-model="dlrURL" />
                    </b-form-group>
                </b-col>
                <b-col md="6">
                    <b-form-group label-for="i-first-shortcode" label="Billing Rate">
                        <b-form-input id="i-first-shortcode" type="number" placeholder="5" v-model="billingRate" />
                    </b-form-group>
                </b-col>
                <b-col cols="12">
                    <b-form-group label="Description" label-for="description-area">
                        <b-form-textarea id="description-area" v-model="description" rows="4" placeholder="Enter your description here" />
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row v-show="Selected=='USSD'">
                <b-col cols="12" class="mb-2">
                    <h5 class="mb-0">
                        USSD Application
                    </h5>
                    <small class="text-muted">Enter information for Sender ID application</small>
                </b-col>
                <b-col md="8">
                    <b-form-group label-for="i-first-ussd" label="USSD App Name">
                        <b-form-input id="i-first-ussd" placeholder="Liden USSD" v-model="ussdAppName" :disabled="isUSSDSet" />
                    </b-form-group>
                </b-col>
                <b-col md="4" v-show="!isUSSDSet">
                    <br />
                    <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-2" id="addApp" @click="createUSSDApp">
                        <b-spinner small v-show="isLoading" />
                        Create App
                    </b-button>
                </b-col>
            </b-row>
            <b-row v-show="isUSSDSet && Selected=='USSD'">
                <b-col md="6">
                    <b-form-group label-for="i-first-network" label="Select USSD Types">
                        <b-form-select v-model="ussdTypesSelected" :options="ussdTypes"></b-form-select>

                    </b-form-group>
                </b-col>

                <b-col md="6">
                    <b-form-group label-for="i-first-network" label="Select Networks">
                        <b-form-select v-model="networkSelected" :options="mobileNetworks"></b-form-select>

                    </b-form-group>
                </b-col>

                <b-col md="6">
                    <b-form-group label-for="i-first-shortcode" label="Access Point">
                        <b-form-input id="i-first-shortcode" type="text" placeholder="*111#" v-model="accessPoint" />
                    </b-form-group>
                </b-col>
                <b-col md="6">
                    <b-form-group label-for="i-first-shortcode" label="Callback Port">
                        <b-form-input id="i-first-shortcode" type="number" placeholder="8000" v-model="portNumber" />
                    </b-form-group>
                </b-col>
                <b-col md="12">
                    <b-form-group label-for="i-first-shortcode" label="Callback URL">
                        <b-form-input id="i-first-shortcode" type="url" placeholder="https://example.com/dlr" v-model="dlrURL" />
                    </b-form-group>
                </b-col>
                <b-col cols="12">
                    <b-form-group label="Default Message" label-for="message-area">
                        <b-form-textarea id="bio-area" v-model="defaultMessage" rows="4" placeholder="Default message here..." />
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row v-show="Selected=='SURVEY'">
            <b-col md="6">
                    <b-form-group label-for="i-first-network">
                        <b-form-radio v-model="Selected" plain name="some-radios3" value="SURVEY" selected disabled>
                        <b-card no-body class="card-developer-meetup">
                            <div class="rounded-top text-center">
                                <b-img src="https://www.nonprofithr.com/wp-content/uploads/2020/04/Survey-time.jpg" alt="Meeting Pic" height="170" />
                            </div>
                            <b-card-body>
                                <!-- metting header -->
                                <div class="meetup-header d-flex align-items-center">

                                    <div class="my-auto">
                                        <b-card-title class="mb-25">
                                            Activated Survey
                                        </b-card-title>
                                        <b-card-text class="mb-0">
                                            e.g Product Performance
                                        </b-card-text>
                                    </div>
                                </div>
                                <!--/ metting header -->

                                <!-- media -->
                                <b-media v-for="survey in SURVEYData" :key="survey.avatar" no-body>

                                    <b-media-body>
                                        <h6 class="mb-0">
                                            {{ survey.title }}
                                        </h6>
                                        <small>{{ survey.subtitle }}</small>
                                    </b-media-body>
                                </b-media>

                                <!-- avatar group -->

                            </b-card-body>

                        </b-card>
                    </b-form-radio>
                    </b-form-group>
                </b-col>
            </b-row>
        </tab-content>

        <!-- Contact Info -->
        <tab-content title="Confirmation" icon="feather icon-map-pin">
            <b-row v-show="Selected=='ShortCode' || Selected=='TwoShortCode' || Selected=='SENDERID'">
                <b-col cols="12" class="mb-2">
                    <h5 class="mb-0">
                        Application Confirmation Application
                    </h5>
                </b-col>
                <b-col md="6">
                    <b-form-group label-for="i-first-shortcode" label="Shortcode">
                        <b-form-input id="i-first-shortcode" placeholder="Fleek" v-model="shortcode" disabled />
                    </b-form-group>
                </b-col>
                <b-col md="6">
                    <b-form-group label-for="i-first-network" label="Select Network">
                        <v-select v-model="networkSelected" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="option" multiple class="flex-grow-1 email-cc-selector" :close-on-select="false"  label="title">
                            <template #option="{ title, icon }">
                                <feather-icon :icon="icon" size="16" class="align-middle mr-50" />
                                <span> {{ title }}</span>
                            </template>
                        </v-select>
                    </b-form-group>
                </b-col>

                <b-col md="6" v-show="Selected=='ShortCode' || Selected=='TwoShortCode'">
                    <b-form-group label-for="i-first-shortcode" label="Callback URL">
                        <b-form-input id="i-first-shortcode" disbaled type="url" placeholder="https://example.com/callback" v-model="callbackURL" />
                    </b-form-group>
                </b-col>
                <b-col md="6" v-show="Selected=='ShortCode' || Selected=='TwoShortCode'">
                    <b-form-group label-for="i-first-shortcode" label="Delivery URL">
                        <b-form-input id="i-first-shortcode" disbaled type="url" placeholder="https://example.com/dlr" v-model="dlrURL" />
                    </b-form-group>
                </b-col>
                <b-col md="6" v-show="Selected=='ShortCode' || Selected=='TwoShortCode'">
                    <b-form-group label-for="i-first-shortcode" label="Billing Rate">
                        <b-form-input id="i-first-shortcode" type="number" placeholder="5" v-model="billingRate" />
                    </b-form-group>
                </b-col>
                <b-col cols="12">
                    <b-form-group label="Description" label-for="description-area">
                        <b-form-textarea id="description-area" v-model="description" rows="4" placeholder="Enter your description here" disabled />
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row v-show="Selected=='USSD'">
                <b-col md="6">
                    <b-form-group label-for="i-first-network" label="Select USSD Types">
                        <b-form-select v-model="ussdTypesSelected" :options="ussdTypes" disabled></b-form-select>

                    </b-form-group>
                </b-col>

                <b-col md="6">
                    <b-form-group label-for="i-first-network" label="Select Networks">
                        <b-form-select v-model="networkSelected" :options="mobileNetworks"></b-form-select>

                    </b-form-group>
                </b-col>

                <b-col md="6">
                    <b-form-group label-for="i-first-shortcode" label="Access Point">
                        <b-form-input id="i-first-shortcode" type="text" placeholder="*405#" v-model="accessPoint" disabled />
                    </b-form-group>
                </b-col>
                <b-col md="6">
                    <b-form-group label-for="i-first-shortcode" label="Callback Port">
                        <b-form-input id="i-first-shortcode" type="number" placeholder="8000" v-model="portNumber" disabled />
                    </b-form-group>
                </b-col>
                <b-col md="12">
                    <b-form-group label-for="i-first-shortcode" label="Callback URL">
                        <b-form-input id="i-first-shortcode" type="url" placeholder="https://example.com/dlr" v-model="dlrURL" disabled />
                    </b-form-group>
                </b-col>
                <b-col cols="12">
                    <b-form-group label="Default Message" label-for="message-area">
                        <b-form-textarea id="bio-area" v-model="defaultMessage" rows="4" placeholder="Default message here..." disabled />
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row v-show="Selected=='SURVEY'">
            <b-col md="12">
                    <b-form-group label-for="i-first-network" >
                        <b-form-radio v-model="Selected" plain name="some-radios3" value="SURVEY" selected disabled>
                        <b-card no-body class="card-developer-meetup">
                            <div class="rounded-top text-center">
                                <b-img src="https://www.nonprofithr.com/wp-content/uploads/2020/04/Survey-time.jpg" alt="Meeting Pic" height="170" />
                            </div>
                            <b-card-body>
                                <!-- metting header -->
                                <div class="meetup-header d-flex align-items-center">

                                    <div class="my-auto">
                                        <b-card-title class="mb-25">
                                            Activated Survey
                                        </b-card-title>
                                        <b-card-text class="mb-0">
                                            e.g Product Performance
                                        </b-card-text>
                                    </div>
                                </div>
                                <!--/ metting header -->

                                <!-- media -->
                                <b-media v-for="survey in SURVEYData" :key="survey.avatar" no-body>

                                    <b-media-body>
                                        <h6 class="mb-0">
                                            {{ survey.title }}
                                        </h6>
                                        <small>{{ survey.subtitle }}</small>
                                    </b-media-body>
                                </b-media>

                                <!-- avatar group -->

                            </b-card-body>

                        </b-card>
                    </b-form-radio>
                    </b-form-group>
                </b-col>
            </b-row>
        </tab-content>

    </form-wizard>

</div>
</template>

<script>
import {
    FormWizard,
    TabContent
} from 'vue-form-wizard'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import Ripple from 'vue-ripple-directive'
import {
    BFormRadio,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BCard,
    BImg,
    BCardBody,
    BCardText,
    BCardTitle,
    BMedia,
    BMediaAside,
    BAvatar,
    BAvatarGroup,
    VBTooltip,
    BMediaBody,
    BButton,
    BSpinner,
    BFormTextarea,
    BFormSelect
} from 'bootstrap-vue'
import $ from 'jquery'
import store from '@/store/index'
export default {
    components: {
        BFormRadio,
        FormWizard,
        TabContent,
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        vSelect,
        // eslint-disable-next-line vue/no-unused-components
        ToastificationContent,
        BCard,
        BImg,
        BCardBody,
        BCardText,
        BCardTitle,
        BMedia,
        BMediaAside,
        BAvatar,
        BAvatarGroup,
        BMediaBody,
        BButton,
        BSpinner,
        BFormTextarea,
        BFormSelect
    },
    directives: {
        'b-tooltip': VBTooltip,
        Ripple,
    },
    data() {
        return {
            billingRate: 5,
            callbackURL: "",
            ussdAppName: "",
            accessPoint: "",
            portNumber: "",
            defaultMessage: "",
            appID: "",
            isLoading: false,
            isUSSDSet: false,
            dlrURL: "",
            Selected: "SENDERID",
            smsData: [{
                avatar: 'CalendarIcon',
                title: 'Branded Sender',
                subtitle: 'Integrate our Bulk SMS API into your service to send branded messages to multiple numbers instanty.'
            }, ],
            twoWayData: [{
                title: 'Shortcode Two-Way',
                subtitle: 'Open a two-way line of commnication with your users and share information or collect feedback from users.'
            }],
            premiumData: [{
                title: 'Premium SMS Shortcode',
                subtitle: 'Generate revenue by delivering entertainment messages to your users either on demand or on subscription.'
            }],
            USSDData: [{
                title: 'USSD Applications!',
                subtitle: 'USSD is a service that allows mobile phone users to interact with a remote application from their device in real time.'
            }, ],
            SURVEYData: [{
                title: 'Survey',
                subtitle: 'Survey allows you to Get real stats of your services on realtime using the Survey'
            }, ],
            description: "",
            shortcode: "",
            option: [{
                title: 'Safaricom',
                value: '1',
                icon: 'CommandIcon',
            },{
                title: 'Airtel',
                value: '2',
                icon: 'CommandIcon',
            },{
                title: 'Telkom',
                value: '3',
                icon: 'CommandIcon',
            } ],
            networkSelected: {
                title: 'Safaricom',
                icon: 'CommandIcon',
            },
            ussdTypes: [],
            mobileNetworks: [],
            ussdTypesSelected: null,
            networkSelected: null

        }
    },
    mounted() {
        this.getUSSDApp()
        this.getUSSDTypes()
        this.getMobileNetwords()
        console.log(JSON.stringify(this.$cookies.get("clientData")))
    },
    methods: {
        formSubmitted() {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: 'Form Submitted',
                    icon: 'EditIcon',
                    variant: 'success',
                },
            })
        },
        checkIfSelected() {
            let vm = this
            if (vm.Selected == "") {
                vm.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'You need to select one of services',
                        icon: 'EditIcon',
                        variant: 'error',
                    },
                })
            } else {
                return true
            }

        },
        checkIfRecordEnter() {
            let vm = this
            console.log(vm.Selected)
            if (vm.Selected == 'SENDERID') {
                if (vm.shortcode == "" || vm.description == "") {
                    vm.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'You need to fill all the form for '+vm.Selected+' Application',
                            icon: 'EditIcon',
                            variant: 'error',
                        },
                    })
                    return false
                } else {
                    return true
                }
            }
            if (vm.Selected == 'ShortCode' || vm.Selected == 'TwoShortCode') {
                if (vm.callbackURL == "" || vm.dlrURL == "") {
                    vm.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'You need to fill all the form for '+vm.Selected+' Application',
                            icon: 'EditIcon',
                            variant: 'error',
                        },
                    })
                    return false
                } else {
                    return true
                }
            }
            if (vm.Selected == 'USSD') {
                if (vm.ussdTypesSelected == null || vm.accessPoint == "") {
                    vm.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'You need to fill all the form for USSD Applications',
                            icon: 'EditIcon',
                            variant: 'error',
                        },
                    })
                    return false
                } else {
                    return true
                }
            }
            if (vm.Selected == 'SURVEY') {
                return true
            }

        },
        ApplyServices() {
            let vm = this
            //'ShortCode' || Selected=='TwoShortCode' || Selected=='SENDERID'
            console.log("test message "+vm.networkSelected)
            if (vm.Selected == 'USSD') {
                vm.setLoading = true
                $('#addUser').html(' Please Wait ...');
                $.post({
                    url: store.state.rootUrl + 'ussd/v1/configure/access_point',
                    post: "POST",
                    headers: {
                        "X-Authorization-Key": vm.$cookies.get("token"),
                        "X-Requested-With": "XMLHttpRequest",
                        "Content-Type": "application/json",
                    },
                    data: JSON.stringify({
                        appId: vm.appID,
                        typeId: vm.ussdTypesSelected,
                        accessPoint: vm.accessPoint,
                        networkId: vm.networkSelected,
                        defaultMessage: vm.defaultMessage,
                        callbackUrl: vm.dlrURL,
                        callbackPortNumber: vm.portNumber,
                    }),
                    success: function (response, status, jQxhr) {
                        vm.setLoading = false
                        $('#addUser').html(' Add');
                        if (response.data.code != 200) {
                            vm.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: response.data.message,
                                    icon: 'EditIcon',
                                    variant: 'error',
                                },
                            })

                        } else {
                            vm.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: response.data.message,
                                    icon: 'EditIcon',
                                    variant: 'success',
                                },
                            })
                            vm.$router.push({
                                name: 'view-request'
                            });
                        }

                    },
                    error: function (jQxhr, status, error) {
                        vm.setLoading = false
                        var statustext = jQxhr.responseJSON.statusDescription;
                        var errorMessage = ""
                        if (!statustext) {
                            errorMessage = "An error occurred. Try again later.";
                        } else {
                            var messageError = ""
                            messageError = jQxhr.responseJSON.data.message
                            if (!messageError) {
                                errorMessage = statustext;
                            } else {
                                errorMessage = statustext + " " + messageError;
                            }

                        }
                        vm.$toast({
                            component: ToastificationContent,
                            props: {
                                title: errorMessage,
                                icon: 'EditIcon',
                                variant: 'error',
                            },
                        })
                    }
                });
            } else if(vm.Selected == 'SURVEY'){
                 vm.setLoading = true
                $('#addUser').html(' Please Wait ...');
                $.post({
                    url: store.state.rootUrl + 'account/v1/request/service',
                    post: "POST",
                    headers: {
                        "X-Authorization-Key": vm.$cookies.get("token"),
                        "X-Requested-With": "XMLHttpRequest",
                        "Content-Type": "application/json",
                    },
                    data: JSON.stringify({
                        serviceId: 18,
                        clientId: vm.$cookies.get("clientData").clientId,
                        inboundCost: 1,
                        responseCost: 2
                    }),
                    success: function (response, status, jQxhr) {
                        vm.setLoading = false
                        $('#addUser').html(' Add');
                        if (response.data.code != 200) {
                            vm.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: response.data.message,
                                    icon: 'EditIcon',
                                    variant: 'error',
                                },
                            })

                        } else {
                            vm.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: response.data.message,
                                    icon: 'EditIcon',
                                    variant: 'success',
                                },
                            })
                            vm.$router.push({
                                name: 'view-request'
                            });
                        }

                    },
                    error: function (jQxhr, status, error) {
                        vm.setLoading = false
                        var statustext = jQxhr.responseJSON.statusDescription;
                        var errorMessage = ""
                        if (!statustext) {
                            errorMessage = "An error occurred. Try again later.";
                        } else {
                            var messageError = ""
                            messageError = jQxhr.responseJSON.data.message
                            if (!messageError) {
                                errorMessage = statustext;
                            } else {
                                errorMessage = statustext + " " + messageError;
                            }

                        }
                        vm.$toast({
                            component: ToastificationContent,
                            props: {
                                title: errorMessage,
                                icon: 'EditIcon',
                                variant: 'error',
                            },
                        })
                    }
                });
            } else {
                let type = 3
                vm.setLoading = true
                if (vm.Selected == 'ShortCode') {
                    type = 1
                }
                if (vm.Selected == 'TwoShortCode') {
                    type = 2
                }
                if (vm.Selected == 'SENDERID') {
                    type = 3
                }
                if (vm.Selected == 'Numeric') {
                    type = 4
                }
                if (vm.Selected == 'WhatsApp') {
                    type = 5
                }
                var networkList = []
                if(vm.networkSelected.length > 0){
                    vm.networkSelected.forEach(function(data) {
                        networkList.push(data.value)
                    });
                }
                
                $('#addUser').html(' Please Wait ...');
                $.post({
                    url: store.state.rootUrl + 'account/v1/request/senderid',
                    post: "POST",
                    headers: {
                        "X-Authorization-Key": vm.$cookies.get("token"),
                        "X-Requested-With": "XMLHttpRequest",
                        "Content-Type": "application/json",
                    },
                    data: JSON.stringify({
                        typeId: type,
                        networks: networkList,
                        shortCode: vm.shortcode,
                        shortCodeDesc: vm.description,
                        keywords: vm.shortcode,
                        callbackUrl: vm.callbackURL,
                        dlrCallbackUrl: vm.dlrURL,
                        billingRate: vm.billingRate
                    }),
                    success: function (response, status, jQxhr) {
                        vm.setLoading = false
                        $('#addUser').html(' Add');
                        if (response.data.code != 200) {
                            vm.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: response.data.message,
                                    icon: 'EditIcon',
                                    variant: 'error',
                                },
                            })

                        } else {
                            vm.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: response.data.message,
                                    icon: 'EditIcon',
                                    variant: 'success',
                                },
                            })
                            vm.$router.push({
                                name: 'view-request'
                            });
                        }

                    },
                    error: function (jQxhr, status, error) {
                        vm.setLoading = false
                        var statustext = jQxhr.responseJSON.statusDescription;
                        var errorMessage = ""
                        if (!statustext) {
                            errorMessage = "An error occurred. Try again later.";
                        } else {
                            var messageError = ""
                            messageError = jQxhr.responseJSON.data.message
                            if (!messageError) {
                                errorMessage = statustext;
                            } else {
                                errorMessage = statustext + " " + messageError;
                            }

                        }
                        vm.$toast({
                            component: ToastificationContent,
                            props: {
                                title: errorMessage,
                                icon: 'EditIcon',
                                variant: 'error',
                            },
                        })
                    }
                });
            }
            vm.billingRate = 5;
            vm.callbackURL = "";
            vm.ussdAppName = "";
            vm.accessPoint = "";
            vm.portNumber = "";
            vm.defaultMessage = "";
            vm.appID = "";
            vm.ussdTypesSelected = null;
            vm.networkSelected = null

        },
        getUSSDApp() {
            let vm = this
            $.get({
                url: store.state.rootUrl + 'ussd/v1/view/apps',
                type: "get",
                async: true,
                crossDomain: true,
                headers: {
                    "X-Authorization-Key": vm.$cookies.get("token"),
                    "X-Requested-With": "XMLHttpRequest",
                    "Content-Type": "application/json",
                },
                success: function (response, status, jQxhr) {
                    if (response.data.code == 200) {
                        let result = response.data.data.data;
                        vm.ussdAppName = result[0].system_name
                        vm.appID = result[0].id
                        vm.isUSSDSet = true
                    }

                },
                error: function (jQxhr, status, error) {}
            });
        },
        createUSSDApp() {
            let vm = this
            $('#addApp').html(' Please Wait ...');
            vm.isLoading = true
            $.post({
                url: store.state.rootUrl + 'ussd/v1/create_app',
                post: "POST",
                headers: {
                    "X-Authorization-Key": vm.$cookies.get("token"),
                    "X-Requested-With": "XMLHttpRequest",
                    "Content-Type": "application/json",
                },
                data: JSON.stringify({
                    clientId: vm.$cookies.get("clientData").clientId,
                    systemName: vm.ussdAppName,
                }),
                success: function (response, status, jQxhr) {
                    $('#addApp').html(' Create App');
                    vm.isLoading = false
                    if (response.data.code != 200) {
                        vm.$toast({
                            component: ToastificationContent,
                            props: {
                                title: response.data.message,
                                icon: 'EditIcon',
                                variant: 'error',
                            },
                        })

                    } else {
                        vm.isUSSDSet = true
                    }

                },
                error: function (jQxhr, status, error) {
                    $('#addApp').html(' Create App');
                    var statustext = jQxhr.responseJSON.statusDescription;
                    var errorMessage = ""
                    if (!statustext) {
                        errorMessage = "An error occurred. Try again later.";
                    } else {
                        var messageError = ""
                        messageError = jQxhr.responseJSON.data.message
                        if (!messageError) {
                            errorMessage = statustext;
                        } else {
                            errorMessage = statustext + " " + messageError;
                        }

                    }
                    vm.$toast({
                        component: ToastificationContent,
                        props: {
                            title: errorMessage,
                            icon: 'EditIcon',
                            variant: 'error',
                        },
                    })
                }
            });
        },
        getUSSDTypes() {
            let vm = this
            $.get({
                url: store.state.rootUrl + 'ussd/v1/view/types',
                type: "get",
                async: true,
                crossDomain: true,
                headers: {
                    "X-Authorization-Key": vm.$cookies.get("token"),
                    "X-Requested-With": "XMLHttpRequest",
                    "Content-Type": "application/json",
                },
                success: function (response, status, jQxhr) {
                    if (response.data.code == 200) {
                        let result = response.data.data;

                        for (var i = 0; i < result.length; i++) {
                            var types = {
                                value: result[i].type_id,
                                text: result[i].name

                            }
                            vm.ussdTypes.push(types)
                        }
                    }

                },
                error: function (jQxhr, status, error) {}
            });
        },
        getMobileNetwords() {
            let vm = this
            $.get({
                url: store.state.rootUrl + 'sms/v1/view/networks',
                type: "get",
                async: true,
                crossDomain: true,
                data: {
                    limit: 20
                },
                headers: {
                    "X-Authorization-Key": vm.$cookies.get("token"),
                    "X-Requested-With": "XMLHttpRequest",
                    "Content-Type": "application/json",
                },
                success: function (response, status, jQxhr) {
                    if (response.data.code == 200) {
                        let result = response.data.data.data;

                        for (var i = 0; i < result.length; i++) {
                            var types = {
                                value: result[i].network_id,
                                text: result[i].network_name

                            }
                            vm.mobileNetworks.push(types)
                        }
                    }

                },
                error: function (jQxhr, status, error) {}
            });
        }
    },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
